<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
        detailHide,
      ]"
    >
      <!-- <vs-button
				v-on:click="doPaymentApproval()"
				color="primary"
				icon-pack="feather"
				icon="icon-inbox"
				>Approval</vs-button
			> -->
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="5%"></vs-th>
          <vs-th>Supplier</vs-th>
          <vs-th sort-key="po_code">Number</vs-th>
          <vs-th sort-key="received_date">Received Date</vs-th>
          <vs-th>Warehouse</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td class="whitespace-no-wrap">
              <template v-if="$store.getters['user/hasPermissions']('view')">
                <vx-tooltip text="Show">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleEdit(data[indextr], indextr)"
                    icon="icon-eye"
                  />
                </vx-tooltip>
              </template>
              <p v-else>No Action</p>
            </vs-td>
            <vs-td :data="data[indextr].supplier_name">
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_code }} -
                  {{ data[indextr].supplier_name }}</span
                >
              </div>
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_address }},
                  {{ data[indextr].supplier_city }} ({{
                    data[indextr].supplier_contact_name
                  }}
                  - {{ data[indextr].supplier_phone }})</span
                >
              </div>
            </vs-td>
            <vs-td>
              PO Number :
              {{ data[indextr].po_code ? data[indextr].po_code : "-" }}<br />
              SR Number :
              {{ data[indextr].sr_ref ? data[indextr].sr_ref : "-" }}
            </vs-td>
            <vs-td :data="data[indextr].posting_date">
              {{
                data[indextr].posting_date ? data[indextr].posting_date : "-"
              }}
            </vs-td>
            <vs-td :data="data[indextr].warehouse_code">
              {{ data[indextr].warehouse_code }} <br />
              {{ data[indextr].warehouse_name }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            class="vertical-divider"
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <!-- form component -->
          <detail
            @close="handleClose"
            :po_code="po_code"
            :po_date="po_date"
            :po_id="po_id"
            :po_virtual_code="po_virtual_code"
            :purchase_receive_code="purchase_receive_code"
            :purchase_receive_id="purchase_receive_id"
            :purchase_return_code="purchase_return_code"
            :purchase_return_id="purchase_return_id"
            :received_date="received_date"
            :supplier_address="supplier_address"
            :supplier_city="supplier_city"
            :supplier_code="supplier_code"
            :supplier_contact_name="supplier_contact_name"
            :supplier_name="supplier_name"
            :supplier_phone="supplier_phone"
            :warehouse_code="warehouse_code"
            :warehouse_id="warehouse_id"
            :warehouse_name="warehouse_name"
            :dn_number="dn_number"
            :notes="notes"
            :source="source"
            :grr_code="grr_code"
            :sr_code="sr_code"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import Detail from "./detail.vue";
export default {
  components: {
    Detail,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "date",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      po_code: "",
      po_date: "",
      po_id: "",
      po_virtual_code: "",
      purchase_receive_code: "",
      purchase_receive_id: "",
      purchase_return_code: "",
      purchase_return_date: "",
      purchase_return_id: "",
      received_date: "",
      supplier_address: "",
      supplier_city: "",
      supplier_code: "",
      supplier_contact_name: "",
      supplier_name: "",
      supplier_phone: "",
      warehouse_code: "",
      warehouse_id: "",
      warehouse_name: "",
      dn_number: "",
      notes: "",
      source: "",
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.record_total;
            this.table.totalPage = resp.data.page_total;
            this.table.totalSearch = resp.data.record_total_search;
            this.table.length = resp.data.record_total_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    handleEdit(data, index) {
      this.dn_number = data.dn_number;
      this.source = data.source;
      this.po_code = data.po_code;
      this.po_date = data.po_date;
      this.po_id = data.po_id;
      this.po_virtual_code = data.po_virtual_code;
      this.purchase_receive_code = data.purchase_receive_code;
      this.purchase_receive_id = data.purchase_receive_id;
      this.purchase_return_code = data.purchase_return_code;
      this.purchase_return_date = data.posting_date;
      this.purchase_return_id = data.purchase_return_id;
      this.received_date = data.received_date;
      this.supplier_address = data.supplier_address;
      this.supplier_city = data.supplier_city;
      this.supplier_code = data.supplier_code;
      this.supplier_contact_name = data.supplier_contact_name;
      this.supplier_name = data.supplier_name;
      this.supplier_phone = data.supplier_phone;
      this.warehouse_code = data.warehouse_code;
      this.warehouse_id = data.warehouse_id;
      this.warehouse_name = data.warehouse_name;
      this.table.data = [];
      this.table.data = this.data;
      this.notes = data.note;
      this.sr_code = data.sr_ref;
      this.grr_code = data.grr_code;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
    getSupplierReturnNumber(sr_number) {
      if (sr_number.includes("; ")) {
        const temp = sr_number.split(";");
        if (temp.length === 2) {
          return temp[0];
        } else {
          return temp.join(", ");
        }
      } else {
        return sr_number;
      }
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>

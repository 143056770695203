<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DN Number"
          name="DN Number"
          v-model="dn_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Notes"
          name="Notes"
          v-model="notes"
          placeholder="Notes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Number"
          name="Supplier Credit Note Number"
          v-model="cn_number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Date"
          name="Supplier Credit Note Date"
          v-model="cn_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Invoice Reference"
          name="Tax Invoice Reference"
          v-model="tax_invoice_reference"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Invoice Date"
          name="Tax Invoice Date"
          v-model="tax_invoice_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Rate"
          name="Tax Rate"
          v-model="tax_rate"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Date"
          name="Tax Return Date"
          v-model="tr_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Posting Date"
          name="PostingDate"
          v-model="posting_date"
          readonly
        />
      </div>
    </div>
    <!-- 
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
          <span>Posting Date</span>
        </div>
      <div class="vx-col w-full">
        <datepicker
          class="w-full"
          name="PostingDate"
          v-model="posting_date"
          placeholder="Select Posting Date"
          readonly
        ></datepicker>
      </div>
    </div> -->

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Settlement Method</label>
        <multiselect
          class="selectExample"
          v-model="settlement.selected"
          :options="settlement.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          @select="SelectedMulti"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
        >
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Price</vs-th>
        <vs-th>Damaged Quantity</vs-th>
        <vs-th>Return</vs-th>
        <vs-th>Debit Note</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span
              ><br />
              <span class="w-5/5">{{ data[indextr].batch }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <!-- {{ parseFloat(table.purchaseOrder.lines.filter(i => {
                return i.item_unit_id = data[indextr].item_unit_id
              })[0].price_pcs).toFixed(2).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} -->
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{ data[indextr].damage_quantity }} {{ data[indextr].unit_name }}
            </div>
          </vs-td>

          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Quantity Return"
                label="Quantity Return"
                :value="
                  data[indextr].return_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Value"
                label="Return Value"
                :value="
                  parseFloat(data[indextr].return_value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Tax"
                label="Return Tax"
                :value="
                  parseFloat(data[indextr].return_tax)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Total"
                label="Return Total"
                :value="
                  parseFloat(data[indextr].return_total_value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                class="w-full mb-2 input-readonly"
                name="Quantity Debit Note"
                label="Quantity Debit Note"
                :value="
                  data[indextr].dn_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                @input="formatNumber(indextr, 'dn_quantity')"
                placeholder="Quantity DN"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].dn_quantity == null && status_submit"
                >This field is required</span
              >
              <vs-input
                v-validate="'required'"
                class="w-full mb-2 input-readonly"
                name="Debit Note Value"
                label="Debit Note Value"
                :value="
                  parseFloat(data[indextr].dn_value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                @input="formatNumber(indextr, 'dn_value')"
                placeholder="DN Value"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].dn_value == null && status_submit"
                >This field is required</span
              >
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Debit Note Tax"
                label="Debit Note Tax"
                :value="
                  parseFloat(data[indextr].dn_tax)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Debit Note Total"
                label="Debit Note Total"
                :value="
                  parseFloat(data[indextr].dn_total_value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DPP"
          name="DPP"
          v-model="dpp"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax"
          name="Tax"
          v-model="total_ppn"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Total"
          name="Total"
          v-model="total_dn"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Return Value"
          name="return Value"
          :value="
            parseFloat(return_value)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Debit Note Value"
          name="Debit Note Value"
          :value="
            parseFloat(dn_value)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Remaining Value"
          name="Remaining Value"
          :value="
            parseFloat(remaining_dn_value)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <span>Reversal Date</span>
      </div>
      <div class="vx-col w-full">
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="ReversalDate"
          v-model="reversal_date"
          placeholder="Select Reversal Date"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-textarea class="w-full" label="Description" v-model="description" />
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            multiple="multiple"
            accept=".jpg, .jpeg, .png .pdf"
          />
        </div>
      </div>
    </div>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button v-if="canEdit" class="mr-3 mb-2" @click="handleAttachment"
            >Add Attachment</vs-button
          >
        </div>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in fileAttachment">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != '' && canEdit">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-eye"
                  @click.stop="downloadFileAwsS3(tr.path)"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete" v-if="canEdit">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-trash"
                    @click.stop="handleDeleteAttachment(i, tr.id)"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div
      class="vx-row mb-2"
      style="
        border-bottom: 1px solid #c1bbff;
        padding-top: 10px;
        max-width: 50%;
        margin: auto;
      "
    ></div>

    <div style="margin-top: 6%">
      <div v-if="used_dn_value == 0 && canReverse">
        <vs-button
          @click="handleReversal(dn_id)"
          icon-pack="feather"
          icon="icon-corner-up-left"
          color="red"
        >
          Reverse
        </vs-button>
      </div>
    </div>
    <!--
    <div class="vx-row mb-2">
      <h5 style="margin: auto">Apply Invoice</h5>
    </div>
    <div class="vx-row mb-2">
      Debit Note Value : {{ parseFloat(dn_value_show).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<br>
      Invoice Selected : {{ parseFloat(invoice_value_selected).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<br>
      Remaining Value : {{ parseFloat(remaining_value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_invoice_open">
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th>Invoice Code</vs-th>
        <vs-th>Invoice Date</vs-th>
        <vs-th>Paid Value</vs-th>
        <vs-th>Unpaid Value</vs-th>
        <vs-th>Paid Now</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td>
            <vs-checkbox
              v-on:click="check(tr.purchase_invoice_id, indextr)"
              :checked="checked.includes(tr.ID)"
            >
            </vs-checkbox>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.invoice_code }}</span>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ dateFormat(tr.invoice_date) }}</span>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.paid_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.unpaid_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            </div>
          </vs-td>
          <vs-td style="text-align: right">
            <template v-if="checked[checked.length - 1] == tr.purchase_invoice_id">
              <vs-input
                class="w-full"
                @keypress="isNumber($event)"
                v-model="dataChecked[checked.length - 1].paid_now"
                @keyup="
                    dataChecked[checked.length - 1].paid_now = formatPrice(
                      dataChecked[checked.length - 1].paid_now.toString() == ''? '0': dataChecked[checked.length - 1].paid_now.toString()
                    )
                  "
              />
            </template>
            <template v-else-if="checked.includes(tr.purchase_invoice_id)">
              {{ tr.paid_now }}
            </template>
            <template v-else> 0 </template>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div style="margin-top: 6%">
      <vs-button v-if="settlement.selected!= 'Payment'" @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Apply
      </vs-button>
    </div>
    -->
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    dn_id: {
      type: Number,
    },
    dn_code: {
      type: String,
    },
    remaining_dn_value: {
      type: Number,
    },
    cn_number: {
      type: String,
    },
    cn_date: {
      type: String,
    },
    tr_date: {
      type: String,
    },
    tr_number: {
      type: String,
    },
    notes: {
      type: String,
    },
    tax_invoice_date: {
      type: String,
    },
    tax_invoice_reference: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        settlement: {
          options: ["Payment", "AP Offset"],
          selected: "Payment",
        },
        used_dn_value: -1,
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          data: [],
          data_invoice_open: [],
        },
        dn_value: 0,
        return_value: 0,
        dn_value_show: 0,
        posting_date: null,
        // posting_date: new Date().toISOString().slice(0,10),
        reversal_date: new Date().toISOString().slice(0, 10),
        // reversal_date: null,
        description: "",
        checked: [],
        dataChecked: [],
        fileAttachment: [],
        file: [],
        tax_rate: "",
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("YYYY-MM-DD");
      }
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.purchase_invoice_id != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.table.data_invoice_open[index]);
      }
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.dn_id);
      form.append("file", this.file[array]);
      return form;
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/debit-note/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    SelectedMulti(selected, id) {
      const before = this.settlement.selected;
      console.log(this.settlement.selected);
      console.log("confirm");
      console.log(selected, id);
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure change Settlement method to " + selected + " ?",
        accept: this.changeSettlement,
        cancel: () => {
          this.settlement.selected = before;
          return;
        },
      });
    },
    changeSettlement() {
      this.$http
        .post("/api/v1/debit-note/change-settlement/" + this.dn_id, {
          settlement: this.settlement.selected,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          this.uploadData(i);
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleSubmit() {
      if (this.checked.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select invoice",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else if (this.invoice_value_selected > this.dn_value) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Remaining value must be greater than 0!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.dataChecked.map((v) => {
          v.paid_now = v.paid_now.replace(/ /g, "");
        });
        this.postData();
        // console.log(this.paramData())
      }
    },
    paramData() {
      return {
        debit_note_id: this.dn_id,
        debit_note_code: this.dn_code,
        posting_date: this.reversal_date,
        description: this.description,
        notes: "-",
        type: 2,
        invoice: this.dataChecked,
      };
    },
    postData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/apply-to-invoice", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn-line/" + this.dn_id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            // this.dn debitNote
            this.table.data = resp.data.records;
            this.table.purchaseOrder = resp.data.purchaseOrder;
            if (resp.data.debitNote.SettlementMethod == "") {
              resp.data.debitNote.SettlementMethod = "AP Offset";
            }
            this.settlement.selected = resp.data.debitNote.SettlementMethod;
            this.posting_date = moment(
              String(new Date(resp.data.debitNote.PostingDate))
            ).format("YYYY-MM-DD");
            // moment(String(new Date(resp.data.tr_date))).format("DD/MM/YYYY")
            // moment(String(date)).format("YYYY-MM-DD")
            // console.log("resp.data.records.debitNote.PostingDate", resp.data.records.debitNote.PostingDate)
            this.fileAttachment = [];
            resp.data.attechment.map((v) => {
              this.fileAttachment.push({
                id: v.ID,
                name: v.NameFile,
                path: v.PathFile,
              });
            });
            let rv = 0;
            let dn = 0;
            this.table.data.forEach(function (element, i) {
              rv += element.return_total_value;
              dn += element.dn_total_value;
            });
            // console.log(rv);
            // console.log(dn);

            this.return_value = rv;
            this.dn_value = resp.data.debitNote.TotalDnValue;
            this.used_dn_value = resp.data.debitNote.UsedDnValue;
            this.tax_rate = `${resp.data.taxRate}%`;

            this.$nextTick(() => {
              this.dn_value_show = resp.data.debitNote.TotalDnValue;
              // this.remaining_value = dn;
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReversal(id) {
      if (this.reversal_date == null) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Reversal date required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      const reversalDate = new Date(this.reversal_date);
      const postingDate = new Date(this.posting_date);
      console.log("reversal date", reversalDate);
      console.log("post date", postingDate);

      if (reversalDate < postingDate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Reversal date must greater then posting date",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/reversal/" + id, {
          reversal_date: this.reversal_date,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getInv() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/get-invoice-unpaid", {
          params: {
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.data_invoice_open = [];
            resp.data.records.map((row) => {
              this.table.data_invoice_open.push({
                ...row,
                ...{
                  paid_now: row.unpaid_value
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                },
              });
            });
            // let _this = this
            // this.table.data_invoice_open.forEach(function (element, i) {
            //   _this.table.data_invoice_open[i].paid_now = element.unpaid_value
            // });
            console.log(resp.data.records);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    // console.log("mounted", this.props)
    if (this.dn_id) {
      this.getData();
      this.getInv();
    }
  },
  computed: {
    dpp() {
      return this.table.data
        .map((item) => item.dn_value)
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total_ppn() {
      return this.table.data
        .map((item) => item.dn_tax)
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total_dn() {
      return this.table.data
        .map((item) => item.dn_total_value)
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    invoice_value_selected: function () {
      console.log("compute");
      return this.dataChecked.reduce(
        (acc, value) =>
          acc +
          parseFloat(value.paid_now.toString().replace(/,/g, "").toString()),
        0
      );
    },
    remaining_value: function () {
      return this.remaining_dn_value - this.invoice_value_selected;
    },
    canEdit() {
      return this.$store.getters["user/hasPermissions"]("edit");
    },
    canReverse() {
      return this.$store.getters["user/hasPermissions"]("revers");
    },
  },
  watch: {
    dn_id() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.getInv();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
